<template>
  <div class="wapper">
    <div class="bg-1">
      <img src="@/assets/images/mp-logo.png" width="106px" height="115px">
      
    </div>
    <div class="bg-card" :style="`background-image: url('${mobileBg}')`">
      <div class="heading-text mb-5 mt-auto">
        <p class="heading mb-1" style="animation: blur 10s ease-out infinite;">Explore</p>
        <h2 class="mt-0">Meravilla Pool City</h2>
        <h4>Shahapur, Maharashtra</h4>
      </div>
    </div>
    <div class="bg-2">
      <p class="head" v-b-modal.projectVideoModalMobile> <img src="@/assets/images/angle.png"> Click to Watch Project Video</p>
      <div class="vdo-box" v-b-modal.projectVideoModalMobile><img src="@/assets/images/play-btnn.png" class="play-btnn"></div>
      <p class="book-now">View Details and Book Now!</p>
      <button class="project-btn" @click="goToHome">View Project Details</button>
      <br>
      <p class="powered_by">Powered by <span>PropertyPistol&nbsp;Technologies</span></p>
    </div>
    <VirtualTour url="https://player.vimeo.com/video/861532068?h=8cf91d424e" id="projectVideoModalMobile" />
  </div>
</template>
<script type="text/javascript">
import mobileBg from '@/assets/images/bg-2.png'
  export default{
    components:{
      VirtualTour:()=>import('@/components/VirtualTour.vue')
    },
    data(){
      return {
        mobileBg
      }
    },
    methods:{
      goToHome(){
        this.$store.dispatch('setIsGifLoading', false);
      },
      playVideo(){
      }
    }
  }
</script>
<style scoped>
.full-screen-video{
  height: 100vh;
  width: 100vw;
  object-fit: fill;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1035;
}

@media (max-width:767px){
  .full-screen-video{
    object-fit: cover;
  }
}

.wapper {
  width: 100%;
  text-align: center;
  overflow: hidden;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  height: 100vh;
  max-height: 100%;
  background-color: #198587;
  z-index: 1034;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.bg-1 {
  height: 50%;
  width: 100%;
  background-size: 100% 100%;
  animation: shrink 5s alternate;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.38%, rgba(0, 0, 0, 0.2) 64.06%), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../assets/images/bg1.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }

  100% {
    background-size: 100% 100%;
  }
}

.bg-card .heading {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.8;
}

.bg-card h2 {
  font-weight: 700;
  font-size: 26px;
  color: #FFFFFF;
  margin: 10px 0px 8px 0px;
}

.bg-card h4 {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.heading-text {
  animation-name: textSlideUp;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  display: block;
  position: relative;
  transform: translateY(-50%);
}

@keyframes textSlideUp {

0%,
50% {
  transform: translateY(0%);
  opacity: 0;
}

60%,
100% {
  transform: translateY(-50%);
  opacity: 1;

}
}

@keyframes slideUp {

  0%,
  50% {
    transform: translateY(50%);
    opacity: 0;
  }

  60%,
  100% {
    transform: translateY(0);
    opacity: 1;

  }
}

.bg-2 {
  
  width: 100%;
  height: 50%;
  padding-bottom: 14px;
  animation-name: slideUp;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 12;

}

.bg-card{
    background-size: 100% 100%;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -33%;
}

.bg-2 .head {
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.8;
}

.vdo-box {
  height: 193px;
  width: 90%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  background-image: url('../assets/images/fast-Intro.gif');
  background-size: 100% 100%;
}

.play-btnn {
  position: relative;
  top: 40%;
  cursor: pointer;
}

.book-now {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.8;
  padding-top: 27px;
}

.project-btn {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 90%;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  border: none;
  color: #198588;
  padding: 12px;
}

.powered_by {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 20px;
}

.powered_by span {
  color: rgba(255, 255, 255, 0.9);
}
</style>